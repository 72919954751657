// export const baseUrl = "http://127.0.0.1:8000/api";
//export const baseUrl = "http://invoidea.in:8002/api";
// export const baseUrl = "http://e-nnovation.net:8002/api";
export const baseUrl = "https://workerman.com/backend/public/api";
export const ImgPath = `https://asset.workerman.com/`;
export const PaymentLinkUrl = `https://workerman.com/`;

export const deafualtImgPath = `https://asset.workerman.com/images/profile_avatar.png`;

export const SuperGeneralSettingUrl = `${baseUrl}/super/settingIndex`;
export const DashboardUrl = `${baseUrl}/dashboard`;

export const loginUrl = `${baseUrl}/login`;
export const forgetUrl = `${baseUrl}/forget-password`;
export const userUrl = `${baseUrl}/users`;
export const createUserUrl = `${baseUrl}/user/store`;
export const editUserUrl = `${baseUrl}/user/edit`;
export const updateUserUrl = `${baseUrl}/user/update`;
export const deleteUserUrl = `${baseUrl}/user/delete`;
export const UserChangeStatusUrl = `${baseUrl}/user/changeStatus`;
export const UserUpdateUserUrl = `${baseUrl}/user/updateUser`;
export const SectionUserUrl = `${baseUrl}/section`;
export const AccountStoreUrl = `${baseUrl}/account_store`;
export const SectionSelectUrl = `${baseUrl}/section_select`;

// product_options
export const Productsizedelete = `${baseUrl}/product_options_value/destroy`;
export const Productsortorder = `${baseUrl}/product_options_value/sortOrder`;


export const Faqlist = `${baseUrl}/faq`;
export const Faqliststore = `${baseUrl}/faq/store`;
export const FaqgroupAdd = `${baseUrl}/faq/groupAdd`;
export const FaqGroupEdit = `${baseUrl}/faq/groupEdit`;
export const FaqgroupUpdate = `${baseUrl}/faq/groupUpdate`;
export const Faqedit = `${baseUrl}/faq/edit`;
export const Faqupdate = `${baseUrl}/faq/update`;
export const FaqChangestatus = `${baseUrl}/faq/changeStatus`;

export const FaqsortOrder = `${baseUrl}/faq/sortOrder`;


export const Productoptionstatuschange = `${baseUrl}/product_options_value/changeStatus`;
export const roleAllUrl = `${baseUrl}/rolesAll`;
export const roleUrl = `${baseUrl}/roles`;
export const createRoleUrl = `${baseUrl}/role/store`;
export const editRoleUrl = `${baseUrl}/role/edit`;
export const ViewRoleUrl = `${baseUrl}/role/view`;

export const RoleSortOrderUrl = `${baseUrl}/role/sortOrder`;
export const roleSectionList = `${baseUrl}/roles/section_list`;

export const updateRoleUrl = `${baseUrl}/role/update`;
export const deleteRoleUrl = `${baseUrl}/role/delete`;
export const permissionUrl = `${baseUrl}/permissions`;

export const generalSettingUrl = `${baseUrl}/setting/general`;
export const generalSettingFirstUrl = `${baseUrl}/setting/general_first`;
export const paymentSettingUrl = `${baseUrl}/setting/payment/`;
export const paymentSettingFirstUrl = `${baseUrl}/setting/payment_first`;
export const websiteSettingUrl = `${baseUrl}/setting/website`;
export const websiteSettingFirstUrl = `${baseUrl}/setting/website_first`;
export const notificationSettingUrl = `${baseUrl}/setting/notification`;
export const notificationSettingFirstUrl = `${baseUrl}/setting/notification_first`;

export const SettingUrl = `${baseUrl}/setting`;
export const storeSettingUrl = `${baseUrl}/setting/store`;

export const BannerSettingUrl = `${baseUrl}/bannerSetting`;
export const BannerUpdateSettingUrl = `${baseUrl}/bannerSetting/update`;

// BANNER GROUP
export const BannerGroupUrl = `${baseUrl}/bannerGroup`;
export const BannerGroupStoreUrl = `${baseUrl}/bannerGroup/store`;
export const BannerGroupEditUrl = `${baseUrl}/bannerGroup/edit`;
export const BannerGroupUpdateUrl = `${baseUrl}/bannerGroup/update`;
export const BannerGroupChangeStatusUrl = `${baseUrl}/bannerGroup/changeStatus`;
// banner
export const BannerListUrl = `${baseUrl}/banner/list`;
export const BannerStoreUrl = `${baseUrl}/banner/store`;
export const BannerEditUrl = `${baseUrl}/banner/edit`;
export const BannerUpdateUrl = `${baseUrl}/banner/update`;
export const BannerChangeStatusUrl = `${baseUrl}/banner/changeStatus`;
export const BannerDestroyUrl = `${baseUrl}/banner/destroy`;
export const BannerSortOrderUrl = `${baseUrl}/banner/sortOrder`;


//FEATURED PRODUCT

export const FeaturedProductUrl = `${baseUrl}/featureProduct`;
export const FeaturedProductStoreUrl = `${baseUrl}/featureProduct/store`;
export const FeaturedProductDeleteUrl = `${baseUrl}/featureProduct/delete`;

//FEATURED GROUP

export const FeaturedGroupListUrl = `${baseUrl}/feature`;
export const FeaturedGroupStoreUrl = `${baseUrl}/feature/store`;
export const FeaturedGroupEditUrl = `${baseUrl}/feature/edit`;
export const FeaturedGroupUpdateUrl = `${baseUrl}/feature/update`;
export const FeaturedGroupChangeStatusUrl = `${baseUrl}/feature/changeStatus`;

// email GROUP
export const EmailGroupUrl = `${baseUrl}/emailGroup`;
export const EmailGroupStoreUrl = `${baseUrl}/emailGroup/store`;
export const EmailGroupEditUrl = `${baseUrl}/emailGroup/edit`;
export const EmailGroupUpdateUrl = `${baseUrl}/emailGroup/update`;
export const EmailGroupChangeStatusUrl = `${baseUrl}/emailGroup/changeStatus`;
export const  EmailGroupSortOrderUrl = `${baseUrl}/emailGroup/sortOrder`;


// email Template
export const EmailTemplateListUrl = `${baseUrl}/emailTemplate`;
export const EmailTemplateCreateUrl = `${baseUrl}/emailTemplate/create`;
export const EmailTemplateViewUrl = `${baseUrl}/emailTemplate/view`;

export const EmailTemplateStoreUrl = `${baseUrl}/emailTemplate/store`;
export const EmailTemplateEditUrl = `${baseUrl}/emailTemplate/edit`;
export const EmailTemplateUpdateUrl = `${baseUrl}/emailTemplate/update`;
export const EmailTemplateChangeStatusUrl = `${baseUrl}/emailTemplate/changeStatus`;
export const EmailTemplateDestroyUrl = `${baseUrl}/emailTemplate/destroy`;

// MENU GROUP
export const MenuGroupUrl = `${baseUrl}/menugroup`;
export const MenuGroupStoreUrl = `${baseUrl}/menugroup/store`;
export const MenuGroupEditUrl = `${baseUrl}/menugroup/edit`;
export const MenuGroupUpdateUrl = `${baseUrl}/menugroup/update`;
export const MenuGroupChangeStatusUrl = `${baseUrl}/menugroup/changeStatus`;

// MENU
export const MenuListUrl = `${baseUrl}/menu`;
export const MenuCreateUrl = `${baseUrl}/menu/create`;
export const MenuStoreUrl = `${baseUrl}/menu/store`;
export const MenuEditUrl = `${baseUrl}/menu/edit`;
export const MenuUpdateUrl = `${baseUrl}/menu/update`;
export const MenuChangeStatusUrl = `${baseUrl}/menu/changeStatus`;
export const MenuDestroyUrl = `${baseUrl}/menu/destroy`;
export const MenuSortOrderUrl = `${baseUrl}/menu/sortOrder`;
// NEWSLETTER
export const NewsletterUrl = `${baseUrl}/newsletter`;
export const NewsletterChangeStatusUrl = `${baseUrl}/newsletter/changeStatus`;
export const NewsletterImportFileUrl = `${baseUrl}/newsletter/ImportFile`;

// orders
export const OrdersUrl = `${baseUrl}/orders`;
export const OrdersChangeStatusUrl = `${baseUrl}/orders/changeStatus`;
export const OrdersDetailsUrl = `${baseUrl}/orders/detail`;

// reviews
export const ReviewsUrl = `${baseUrl}/reviews`;
export const ReviewsChangeStatusUrl = `${baseUrl}/reviews/changeStatus`;
export const ReviewsStoreUrl = `${baseUrl}/reviews/store`;
export const ReviewsCreateUrl = `${baseUrl}/reviews/create`;

//enquiry
export const EnquiryUrl = `${baseUrl}/enquiry`;
export const EnquiryChangeStatusUrl = `${baseUrl}/enquiry/changeStatus`;
export const EnquiryStoreUrl = `${baseUrl}/enquiry/store`;

//superEnquiry

export const SupEnquiryUrl = `${baseUrl}/superEnquiry`;
export const SupEnquiryChangeStatusUrl = `${baseUrl}/superEnquiry/changeStatus`;
export const SupEnquiryStoreUrl = `${baseUrl}/superEnquiry/store`;


//SettingKeyValueUrl
export const SettingKeyValueUrl = `${baseUrl}/settingValue`;
export const SettingKeyValueStoreUrl = `${baseUrl}/settingValue/store`;

//shipping setting
export const shippingMethodListUrl = `${baseUrl}/shippingMethod/list`;
export const shippingUpdateUrl = `${baseUrl}/shippingMethod/shippingUpdate`;

//shipping method
export const CountryList = `${baseUrl}/paymentMethod/create`;
export const shippingMethodUrl = `${baseUrl}/shippingMethod`;
export const shippingMethodUrlAll = `${baseUrl}/shippingMethod/all`;
export const shippingMethodStoreUrl = `${baseUrl}/shippingMethod/store`;
export const shippingMethodEditUrl = `${baseUrl}/shippingMethod/edit`;
export const CountryCreateList = `${baseUrl}/shippingMethod/create`;
export const shippingMethodUpdateUrl = `${baseUrl}/shippingMethod/update`;
export const shippingMethodDetailsUrl = `${baseUrl}/shippingMethod/details`;
export const shippingMethodDeleteUrl = `${baseUrl}/shippingMethod/delete`;
export const shippingMethodChangeStatusUrl = `${baseUrl}/shippingMethod/changeStatus`;

//app setting Group
export const AppSettingGroupUrl = `${baseUrl}/appsettingsgroup`;
export const AppSettingGroupUpdateUrl = `${baseUrl}/appsettingsgroup/update`;
export const AppSettingGroupSortUpdateUrl = `${baseUrl}/appsettingsgroup/sortOrder`;
export const AppSettingGroupStoreUrl = `${baseUrl}/appsettingsgroup/store`;
export const AppSettingGroupStatusUrl = `${baseUrl}/appsettingsgroup/changeStatus`;
export const AppSettingGroupCreateUrl = `${baseUrl}/appsettingsgroup/create`;
export const AppSettingGroupIteamUrl = `${baseUrl}/appsettings/per_page_item`;

export const AppSettingGroupEditUrl = `${baseUrl}/appsettingsgroup/edit`;
export const AppSettingGroupSortOrderUrl = `${baseUrl}/appsettingsgroup/sortOrder`;


// app setting

export const AppSettingStatusUrl = `${baseUrl}/appsettings/changeStatus`;
export const AppGroupEditUrl = `${baseUrl}/appsettings/edit`;
export const AppSettingSortOrderUrl = `${baseUrl}/appsettings/sortOrder`;


// page
export const PagesUrl = `${baseUrl}/pages`;
export const PagesStoreUrl = `${baseUrl}/pages/store`;
export const PagesEditUrl = `${baseUrl}/pages/edit`;
export const PagesUpdateUrl = `${baseUrl}/pages/update`;
export const PagesChangeStatusUrl = `${baseUrl}/pages/changeStatus`;
export const PagesDeleteUrl = `${baseUrl}/pages/destroy`;
export const PagesCreateUrl = `${baseUrl}/pages/create`;
export const PagesVieweUrl = `${baseUrl}/pages/view`;


// page
export const LandingPagesUrl = `${baseUrl}/landingpages`;
export const LandingPagesStoreUrl = `${baseUrl}/landingpages/store`;
export const LandingPagesEditUrl = `${baseUrl}/landingpages/edit`;
export const LandingPagesUpdateUrl = `${baseUrl}/landingpages/update`;
export const LandingPagesChangeStatusUrl = `${baseUrl}/landingpages/changeStatus`;
export const LandingPagesDeleteUrl = `${baseUrl}/landingpages/destroy`;
export const LandingPagesCreateUrl = `${baseUrl}/landingpages/create`;

export const categoryUrl = `${baseUrl}/categories`;
export const categoryStoreUrl = `${baseUrl}/categories/store`;
export const categoryEditUrl = `${baseUrl}/categories/edit`;
export const categoryUpdateUrl = `${baseUrl}/categories/update`;
export const categoryChangeStatusUrl = `${baseUrl}/categories/changeStatus`;
export const categoryIsFeaturedUrl = `${baseUrl}/categories/isfeatured`;
export const categorySortOrderUrl = `${baseUrl}/categories/sortOrder`;

export const createSubCategoryUrl = `${baseUrl}/categories/create_sub_category`;

export const BusineescategoryUrl = `${baseUrl}/businesscategories`;
export const BusineescategoryStoreUrl = `${baseUrl}/businesscategories/store`;
export const BusineescategoryEditUrl = `${baseUrl}/businesscategories/edit`;
export const BusineescategoryUpdateUrl = `${baseUrl}/businesscategories/update`;
export const BusineescategoryChangeStatusUrl = `${baseUrl}/businesscategories/changeStatus`;
export const BusineescategoryIsFeaturedUrl = `${baseUrl}/businesscategories/isfeatured`;
export const BusineescreateSubCategoryUrl = `${baseUrl}/businesscategories/create_sub_category`;
export const BusinesscategorySortOrderUrl = `${baseUrl}/businesscategories/sortOrder`;



export const productUrl = `${baseUrl}/product`;
export const productCreateUrl = `${baseUrl}/product/create`;
export const productStoreUrl = `${baseUrl}/product/store`;
export const productEditUrl = `${baseUrl}/product/edit`;
export const productShowUrl = `${baseUrl}/product/show`;

export const productUpdateUrl = `${baseUrl}/product/update`;
export const productChangeStatusUrl = `${baseUrl}/product/changeStatus`;


export const MediaUrl = `${baseUrl}/media`;

export const productOptionsUrl = `${baseUrl}/product_options`;
export const productOptionsCreateUrl = `${baseUrl}/product_options/create`;
export const productOptionsStoreUrl = `${baseUrl}/product_options/store`;
export const productOptionsEditUrl = `${baseUrl}/product_options/edit`;
export const productOptionsUpdateUrl = `${baseUrl}/product_options/update`;
export const productOptionsChangeStatusUrl = `${baseUrl}/product_options/changeStatus`;

export const productOptionsValueUrl = `${baseUrl}/product_options_value`;
export const productOptionsValueCreateUrl = `${baseUrl}/product_options_value/create`;
export const productOptionsValueStoreUrl = `${baseUrl}/product_options_value/store`;
export const productOptionsValueEditUrl = `${baseUrl}/product_options_value/edit`;
export const productOptionsValueUpdateUrl = `${baseUrl}/product_options_value/update`;
export const productOptionsValueChangeStatusUrl = `${baseUrl}/product_options_value/changeStatus`;

export const ListingUrl = `${baseUrl}/listing`;
export const ListingCreateUrl = `${baseUrl}/listing/create`;
export const ListingEditUrl = `${baseUrl}/listing/edit`;
export const ListingChangeStatusUrl = `${baseUrl}/listing/changeStatus`;
export const ListingStoreUrl = `${baseUrl}/listing/store`;
export const ListingUpdateUrl = `${baseUrl}/listing/update`;
export const ListingShowUrl = `${baseUrl}/listing/show`;
export const ListingSortOrderUrl = `${baseUrl}/listing/sortOrder`;


export const ServiceSubscriptionUrl = `${baseUrl}/serviceSubs`;
export const ServiceSubscriptionDetailsUrl = `${baseUrl}/serviceSubs/details`;
export const ServiceSubscriptionStatusUrl = `${baseUrl}/serviceSubs/approveStatus`;
export const ServiceSubscriberChangeStatusUrl = `${baseUrl}/serviceSubs/changeStatus`;

export const demoUrl = `${baseUrl}/demo`;

export const TemplateComponentSortorder = `${baseUrl}/templateComponent/sortOrder`;
//Ticket
export const CRMTicketUrl = `${baseUrl}/tickets`;
export const CRMTicketStatusUrl = `${baseUrl}/tickets/changeStatus`;
export const TicketCreateUrl = `${baseUrl}/tickets/create`;
export const TicketStoreUrl = `${baseUrl}/tickets/store`;
export const TicketDetailsUrl = `${baseUrl}/tickets/details`;
export const TicketReplyUrl = `${baseUrl}/tickets/send_reply`;


export const planUrl = `${baseUrl}/plan`;
export const planStoreUrl = `${baseUrl}/plan/add`;
export const planEditUrl = `${baseUrl}/plan/edit`;
export const planUpdateUrl = `${baseUrl}/plan/update`;
export const planChangeStatusUrl = `${baseUrl}/plan/changeStatus`;
export const planSortOrderUpdate = `${baseUrl}/plan/sortOrder`;




export const planPriceUrl = `${baseUrl}/pricePlan`;
export const planPriceStoreUrl = `${baseUrl}/pricePlan/add`;
export const planPriceEditUrl = `${baseUrl}/pricePlan/edit`;
export const planPriceUpdateUrl = `${baseUrl}/pricePlan/update`;
export const planPriceCreateUrl = `${baseUrl}/pricePlan/create`;

export const planPriceChangeStatusUrl = `${baseUrl}/pricePlan/changeStatus`;


export const planToFeatureUrl = `${baseUrl}/planfeature`;
export const planToFeatureStoreUrl = `${baseUrl}/planfeature/store`;
export const planToFeatureCreateUrl = `${baseUrl}/planfeature/create`;
export const planToFeatureEditUrl = `${baseUrl}/planfeature/edit`;



export const PlanFeatureUrl = `${baseUrl}/plan-features`;

export const PlanFeatureStoreUrl = `${baseUrl}/plan-features/store`;
export const PlanFeatureStatusUrl = `${baseUrl}/plan-features/changeStatus`;
export const PlanFeatureEditUrl = `${baseUrl}/plan-features/edit`;

export const PlanFeatureUpdateUrl = `${baseUrl}/plan-features/update`;

export const PlanFeatureSortOrderUrl = `${baseUrl}/plan-features/sort-order`;

export const BlogPagesVieweUrl = `${baseUrl}/post/view`;

export const departmentUrl = `${baseUrl}/department`;
export const departmentAllUrl = `${baseUrl}/department/all`;
export const departmentStoreUrl = `${baseUrl}/department/store`;
export const departmentEditUrl = `${baseUrl}/department/edit`;
export const departmentUpdateUrl = `${baseUrl}/department/update`;
export const departmentDeleteUrl = `${baseUrl}/department/delete`;

export const supplierUrl = `${baseUrl}/supplier`;
export const supplierAllUrl = `${baseUrl}/supplier/all`;
export const supplierStoreUrl = `${baseUrl}/supplier/store`;
export const supplierEditUrl = `${baseUrl}/supplier/edit`;
export const supplierUpdateUrl = `${baseUrl}/supplier/update`;
export const supplierDeleteUrl = `${baseUrl}/supplier/delete`;
export const supplierStatusUrl = `${baseUrl}/supplier/changeStatus`;


export const SliderUrl = `${baseUrl}/slider`;
export const SliderStoreUrl = `${baseUrl}/slider/store`;
export const SliderEditUrl = `${baseUrl}/slider/edit`;
export const SliderUpdateUrl = `${baseUrl}/slider/update`;
export const SliderStatusUrl = `${baseUrl}/slider/changeStatus`;


export const SliderListUrl = `${baseUrl}/sliderImages`;
export const SliderImagesUrl = `${baseUrl}/sliderImages/list`;
export const SliderImagesStoreUrl = `${baseUrl}/sliderImages/store`;
export const SliderImagesEditUrl = `${baseUrl}/sliderImages/edit`;
export const SliderImagesUpdateUrl = `${baseUrl}/sliderImages/update`;
export const SliderImagesStatusUrl = `${baseUrl}/sliderImages/changeStatus`;
export const SliderImagesSortOrderUrl = `${baseUrl}/sliderImages/sortOrder`;
export const SliderImagesDeleteUrl = `${baseUrl}/sliderImages/destroy`;



export const leadSourceUrl = `${baseUrl}/lead_source`;
export const leadSourceAllUrl = `${baseUrl}/lead_source/all`;
export const leadSourceStoreUrl = `${baseUrl}/lead_source/store`;
export const leadSourceEditUrl = `${baseUrl}/lead_source/edit`;
export const leadSourceUpdateUrl = `${baseUrl}/lead_source/update`;
export const leadSourceDeleteUrl = `${baseUrl}/lead_source/delete`;
export const leadSourceStatusUrl = `${baseUrl}/lead_source/changeStatus`;

export const leadIndustryUrl = `${baseUrl}/lead_industry`;
export const leadIndustryAllUrl = `${baseUrl}/lead_industry/all`;
export const leadIndustryStoreUrl = `${baseUrl}/lead_industry/store`;
export const leadIndustryEditUrl = `${baseUrl}/lead_industry/edit`;
export const leadIndustryUpdateUrl = `${baseUrl}/lead_industry/update`;
export const leadIndustryDeleteUrl = `${baseUrl}/lead_industry/delete`;
export const leadIndustryStatusUrl = `${baseUrl}/lead_industry/changeStatus`;

export const CRMleadStatusUrl = `${baseUrl}/lead_status`;
export const leadStatusAllUrl = `${baseUrl}/lead_status/all`;
export const leadStatusStoreUrl = `${baseUrl}/lead_status/store`;
export const leadStatusEditUrl = `${baseUrl}/lead_status/edit`;
export const leadStatusUpdateUrl = `${baseUrl}/lead_status/update`;
export const leadStatusDeleteUrl = `${baseUrl}/lead_status/delete`;
export const leadStatusStatusUrl = `${baseUrl}/lead_status/changeStatus`;

export const TaxSettingUrl = `${baseUrl}/crm_setting_tax`;
export const TaxSettingStoreUrl = `${baseUrl}/crm_setting_tax/store`;
export const TaxSettingEditUrl = `${baseUrl}/crm_setting_tax/edit`;
export const TaxSettingUpdateUrl = `${baseUrl}/crm_setting_tax/update`;
export const TaxSettingDeleteUrl = `${baseUrl}/crm_setting_tax/delete`;
export const TaxSettingStatusUrl = `${baseUrl}/crm_setting_tax/changeStatus`;

export const TaxGroupSettingUrl = `${baseUrl}/crm_setting_tax_group`;
export const TaxGroupSettingCreateUrl = `${baseUrl}/crm_setting_tax_group/create`;
export const TaxGroupSettingStoreUrl = `${baseUrl}/crm_setting_tax_group/store`;
export const TaxGroupSettingEditUrl = `${baseUrl}/crm_setting_tax_group/edit`;
export const TaxGroupSettingUpdateUrl = `${baseUrl}/crm_setting_tax_group/update`;
export const TaxGroupSettingDeleteUrl = `${baseUrl}/crm_setting_tax_group/delete`;
export const TaxGroupSettingStatusUrl = `${baseUrl}/crm_setting_tax_group/changeStatus`;

// web setting
export const WebSettingUrl = `${baseUrl}/websettings`;
export const WebSettingUpdateUrl = `${baseUrl}/websettings/update`;
export const WebSettingCreateUrl = `${baseUrl}/websettings/create`;
export const WebSettingStoreUrl = `${baseUrl}/websettings/store`;

//web setting group

export const WebSettingGroupUrl = `${baseUrl}/web_settings_group`;
export const WebSettingGroupUpdateUrl = `${baseUrl}/web_settings_group/update`;
export const WebSettingGroupStoreUrl = `${baseUrl}/web_settings_group/store`;
export const WebSettingGroupStatusUrl = `${baseUrl}/web_settings_group/changeStatus`;
export const WebSettingGroupCreateUrl = `${baseUrl}/web_settings_group/create`;
export const WebSettingGroupEditUrl = `${baseUrl}/web_settings_group/edit`;
export const WebSettingSortOrderUrl = `${baseUrl}/web_settings_group/sortOrder`;


// web setting group key
export const WebSettingGroupKeyUrl = `${baseUrl}/web_setting_group_key`;
export const WebSettingGroupKeyUpdateUrl = `${baseUrl}/web_setting_group_key/update`;
export const WebSettingGroupKeyCreateUrl = `${baseUrl}/web_setting_group_key/create`;
export const WebSettingGroupKeyStoreUrl = `${baseUrl}/web_setting_group_key/store`;
export const WebSettingGroupKeyStatusUrl = `${baseUrl}/web_setting_group_key/changeStatus`;
export const WebSettingGroupKeyEditUrl = `${baseUrl}/web_setting_group_key/edit`;
export const WebSettingGroupKeyOrderUrl = `${baseUrl}/web_setting_group_key/sortOrder`;

// serviceplan
export const servicePlanUrl = `${baseUrl}/servicePlan`;
export const servicePlanStoreUrl = `${baseUrl}/servicePlan/add`;
export const servicePlanEditUrl = `${baseUrl}/servicePlan/edit`;
export const servicePlanUpdateUrl = `${baseUrl}/servicePlan/update`;
export const servicePlanChangeStatusUrl = `${baseUrl}/servicePlan/changeStatus`;




// app setting
export const AppSettingUrl = `${baseUrl}/appsettings`;
export const AppSettingUpdateUrl = `${baseUrl}/appsettings/update`;
export const AppSettingCreateUrl = `${baseUrl}/appsettings/create`;
export const AppSettingStoreUrl = `${baseUrl}/appsettings/store`;

export const PaymentTermSettingUrl = `${baseUrl}/crm_setting_payment_terms`;
export const PaymentTermSettingStoreUrl = `${baseUrl}/crm_setting_payment_terms/store`;
export const PaymentTermSettingEditUrl = `${baseUrl}/crm_setting_payment_terms/edit`;
export const PaymentTermSettingUpdateUrl = `${baseUrl}/crm_setting_payment_terms/update`;
export const PaymentTermSettingDeleteUrl = `${baseUrl}/crm_setting_payment_terms/delete`;
export const PaymentTermSettingStatusUrl = `${baseUrl}/crm_setting_payment_terms/changeStatus`;

export const leadUrl = `${baseUrl}/lead`;
export const leadAllUrl = `${baseUrl}/lead/all`;
export const leadCreateUrl = `${baseUrl}/lead/create`;
export const leadStoreUrl = `${baseUrl}/lead/store`;
export const leadImportFileUrl = `${baseUrl}/lead/import/file`;
export const leadStatusTabUrl = `${baseUrl}/lead/status/tab`;
export const leadViewUrl = `${baseUrl}/lead/view`;
export const leadFollowUpStoreUrl = `${baseUrl}/lead/followup/store`;
export const leadEditUrl = `${baseUrl}/lead/edit`;
export const leadUpdateUrl = `${baseUrl}/lead/update`;
export const leadDeleteUrl = `${baseUrl}/lead/delete`;
export const leadStatusUrl = `${baseUrl}/lead/changeStatus`;

export const customerUrl = `${baseUrl}/crm_customer`;
export const customerAllUrl = `${baseUrl}/crm_customer/all`;
export const customerCreateUrl = `${baseUrl}/crm_customer/create`;
export const customerStoreUrl = `${baseUrl}/crm_customer/store`;
export const customerImportFileUrl = `${baseUrl}/crm_customer/import/file`;
export const customerStatusTabUrl = `${baseUrl}/crm_customer/status/tab`;
export const customerViewUrl = `${baseUrl}/crm_customer/view`;
export const customerFollowUpStoreUrl = `${baseUrl}/crm_customer/followup/store`;
export const customerEditUrl = `${baseUrl}/crm_customer/edit`;
export const customerUpdateUrl = `${baseUrl}/crm_customer/update`;
export const customerDeleteUrl = `${baseUrl}/crm_customer/delete`;
export const customerStatusUrl = `${baseUrl}/crm_customer/changeStatus`;
export const customerIsDefaultUrl = `${baseUrl}/crm_customer/isdefaultupdate`;

export const QuotationUrl = `${baseUrl}/crm_quotation`;
export const QuotationAllUrl = `${baseUrl}/crm_quotation/all`;
export const QuotationCreateUrl = `${baseUrl}/crm_quotation/create`;
export const QuotationStoreUrl = `${baseUrl}/crm_quotation/store`;
export const QuotationCustomerDataUrl = `${baseUrl}/crm_quotation/customer/alldata`;
export const QuotationEditUrl = `${baseUrl}/crm_quotation/edit`;
export const QuotationUpdateUrl = `${baseUrl}/crm_quotation/update`;
export const QuotationDeleteUrl = `${baseUrl}/crm_quotation/delete`;
export const QuotationStatusUrl = `${baseUrl}/crm_quotation/changeStatus`;
export const QuotationCustomerInfoStoreStatusUrl = `${baseUrl}/crm_quotation/contactsdata`;

export const brandUrl = `${baseUrl}/brand`;
export const brandAllUrl = `${baseUrl}/brand/all`;
export const brandStoreUrl = `${baseUrl}/brand/store`;
export const brandEditUrl = `${baseUrl}/brand/edit`;
export const brandUpdateUrl = `${baseUrl}/brand/update`;
export const brandDeleteUrl = `${baseUrl}/brand/delete`;
export const brandStatusUrl = `${baseUrl}/brand/changeStatus`;

export const designationUrl = `${baseUrl}/designation`;
export const designationAllUrl = `${baseUrl}/designation/all`;
export const designationStoreUrl = `${baseUrl}/designation/store`;
export const designationEditUrl = `${baseUrl}/designation/edit`;
export const designationUpdateUrl = `${baseUrl}/designation/update`;
export const designationDeleteUrl = `${baseUrl}/designation/delete`;

export const staffUrl = `${baseUrl}/staff`;
export const staffCreateUrl = `${baseUrl}/staff/create`;
export const staffAllUrl = `${baseUrl}/staff/all`;
export const staffStoreUrl = `${baseUrl}/staff/store`;
export const staffEditUrl = `${baseUrl}/staff/edit`;
export const staffUpdateUrl = `${baseUrl}/staff/update`;
export const staffDeleteUrl = `${baseUrl}/staff/delete`;
export const staffChangeStatusUrl = `${baseUrl}/staff/changeStatus`;
export const staffVerifiedUrl = `${baseUrl}/staff/verify`;
export const staffTerminateUrl = `${baseUrl}/staff/terminate`;

// temp gallery
export const tempUploadFileUrl = `${baseUrl}/temp/upload/file`;

export const subscriberUrl = `${baseUrl}/subscribers`;
export const subscriberAllUrl = `${baseUrl}/subscribers/all`;
export const subscriberStoreUrl = `${baseUrl}/subscribers/add`;
export const subscriberDetailsUrl = `${baseUrl}/subscribers/details`;
export const subscriberEditUrl = `${baseUrl}/subscribers/edit`;
export const subscriberCreateUrl = `${baseUrl}/subscribers/create`;




export const subscriberUpdateUrl = `${baseUrl}/subscribers/update`;
export const subscriberChangeStatusUrl = `${baseUrl}/subscribers/changeStatus`;

export const subscriptionUrl = `${baseUrl}/subscriptions`;
export const subscriptionIndustryPlanUrl = `${baseUrl}/subscriptions/industry_plan`;
export const subscriptionIndustryUrl = `${baseUrl}/subscriptions/create`;

export const subscriptionStoreUrl = `${baseUrl}/subscriptions/add`;
export const subscriptionDetailsUrl = `${baseUrl}/subscriptions/details`;
export const subscriptionStatusUrl = `${baseUrl}/subscriptions/approveStatus`;
export const subscriptionStatusChangeUrl = `${baseUrl}/subscriptions/changeStatus`;
export const subscriptionHistStatusChangeUrl = `${baseUrl}/subscriptions/SubapproveStatus`;

export const subscriptionEditUrl = `${baseUrl}/subscriptions/edit`;
export const subscriptionUpdateUrl = `${baseUrl}/subscriptions/update`;

export const languageUrl = `${baseUrl}/language`;
export const languageUrlAll = `${baseUrl}/language/all`;
export const languageStoreUrl = `${baseUrl}/language/store`;
export const languageEditUrl = `${baseUrl}/language/edit`;
export const languageUpdateUrl = `${baseUrl}/language/update`;
export const languageDetailsUrl = `${baseUrl}/language/details`;
export const languageDeleteUrl = `${baseUrl}/language/delete`;
export const languageChangeStatusUrl = `${baseUrl}/language/changeStatus`;
export const translationUpdateUrl = `${baseUrl}/translation/update`;
export const translationStoreKeysUrl = `${baseUrl}/translation/add_key`;
export const translationEditKeysValueUrl = `${baseUrl}/translation/trans_edit_key_value`;
export const translationDeleteKeyUrl = `${baseUrl}/translation/trans_delete_key`;

export const timezoneUrlAll = `${baseUrl}/timezone/all`;

export const countryUrl = `${baseUrl}/country`;
export const countryUrlAll = `${baseUrl}/country/all`;
export const countryStoreUrl = `${baseUrl}/country/store`;
export const countryEditUrl = `${baseUrl}/country/edit`;
export const countryUpdateUrl = `${baseUrl}/country/update`;
export const countryDetailsUrl = `${baseUrl}/country/details`;
export const countryDeleteUrl = `${baseUrl}/country/delete`;

export const industryUrl = `${baseUrl}/industry`;
export const industryUrlAll = `${baseUrl}/industry/all`;
export const industryCreateList = `${baseUrl}/industry/create`;
export const industryStoreUrl = `${baseUrl}/industry/store`;
export const industryEditUrl = `${baseUrl}/industry/edit`;
export const industryUpdateUrl = `${baseUrl}/industry/update`;
export const industryDetailsUrl = `${baseUrl}/industry/details`;
export const industryDeleteUrl = `${baseUrl}/industryCategory/delete`;
export const functionSelectUrl = `${baseUrl}/industry/fun_select`;
export const industryChangeStatusUrl = `${baseUrl}/industry/changeStatus`;

export const industryCategoryUrl = `${baseUrl}/industryCategory`;
export const industryCategoryList = `${baseUrl}/industryCategory/create`;
export const industryCategoryStoreUrl = `${baseUrl}/industryCategory/store`;
export const industryCategoryEditUrl = `${baseUrl}/industryCategory/edit`;
export const industryCategoryUpdateUrl = `${baseUrl}/industryCategory/update`;
export const industryCategoryStatusUrl = `${baseUrl}/industryCategory/changeStatus`;

export const paymentMethodUrl = `${baseUrl}/paymentMethod`;
export const paymentMethodListUrl = `${baseUrl}/paymentMethod/list`;
export const paymentMethodUrlAll = `${baseUrl}/paymentMethod/all`;
export const paymentMethodStoreUrl = `${baseUrl}/paymentMethod/store`;
export const paymentUpdateUrl = `${baseUrl}/paymentMethod/paymentUpdate`;
export const paymentMethodEditUrl = `${baseUrl}/paymentMethod/edit`;
export const paymentMethodUpdateUrl = `${baseUrl}/paymentMethod/update`;
export const paymentMethodDetailsUrl = `${baseUrl}/paymentMethod/details`;
export const paymentMethodDeleteUrl = `${baseUrl}/paymentMethod/delete`;
export const paymentMethodChangeStatusUrl = `${baseUrl}/paymentMethod/changeStatus`;

export const moduleUrl = `${baseUrl}/module`;
export const moduleUrlAll = `${baseUrl}/module/all`;
export const moduleStoreUrl = `${baseUrl}/module/store`;
export const moduleEditUrl = `${baseUrl}/module/edit`;
export const moduleUpdateUrl = `${baseUrl}/module/update`;
export const moduleSectionEditUrl = `${baseUrl}/module/section/edit`;
export const moduleSectionUpdateUrl = `${baseUrl}/module/section/update`;
export const moduleChangeStatusUrl = `${baseUrl}/module/change_status`;
export const moduleUpdateSortOrderUrl = `${baseUrl}/module/update/sort_order`;
export const moduleSectionStoreUrl = `${baseUrl}/module/store_module_section`;
export const moduleParentSectionListUrl = `${baseUrl}/module/section_list`;

export const TemplateUrl = `${baseUrl}/template`;
export const TemplateListUrl = `${baseUrl}/template/list`;
export const TemplateStoreUrl = `${baseUrl}/template/store`;
export const TemplateEditUrl = `${baseUrl}/template/edit`;
export const TemplateUpdateUrl = `${baseUrl}/template/update`;
export const TemplateSectionStoreUrl = `${baseUrl}/template/sectionStore`;
export const TemplateSectionEditUrl = `${baseUrl}/template/sectionEdit`;
export const TemplateSectionUpdateUrl = `${baseUrl}/template/sectionUpdate`;
export const TemplateChangeStatusUrl = `${baseUrl}/template/changeStatus`;
export const TemplateSectionGroupStoreUrl = `${baseUrl}/template/sectionGroupStore`;
export const TemplateSectionGroupEditUrl = `${baseUrl}/template/sectionGroupedit`;
export const TemplateSectionGroupUpdateUrl = `${baseUrl}/template/sectionGroupUpdate`;
export const TemplateDetailUrl = `${baseUrl}/template/detail`;
export const TemplateSettingUrl = `${baseUrl}/templateSetting`;
export const TemplateSettingStoreUrl = `${baseUrl}/templateSetting/store`;
export const TemplateComponentListUrl = `${baseUrl}/template/componentlist`;
export const TemplateComponentSettingStoreUrl = `${baseUrl}/template/componentsetting/store`;
export const TemplateComponentSettingDelUrl = `${baseUrl}/template/componentsetting/delete`;
export const TempComponentSettingUrl = `${baseUrl}/componentsetting`;
export const TempComponentSettingChangeStatusUrl = `${baseUrl}/componentsetting/changeStatus`;
export const TempComponentSettingSortOrderUrl = `${baseUrl}/componentsetting/sortOrder`;

//templateComponent
export const TemplateComponentUrl = `${baseUrl}/templateComponent`;
export const TemplateComponentCreateUrl = `${baseUrl}/templateComponent/create`;
export const TemplateComponentStoreUrl = `${baseUrl}/templateComponent/store`;
export const TemplateComponentEditUrl = `${baseUrl}/templateComponent/edit`;
export const TemplateComponentUpdateUrl = `${baseUrl}/templateComponent/update`;
export const TemplateComponentChangeStatusUrl = `${baseUrl}/templateComponent/changeStatus`;

export const BlogPostUrl = `${baseUrl}/post`;
export const BlogPostStoreUrl = `${baseUrl}/post/store`;
export const BlogPostCreateUrl = `${baseUrl}/post/create`;
export const BlogPostEditUrl = `${baseUrl}/post/edit`;
export const BlogPostUpdateUrl = `${baseUrl}/post/update`;
export const BlogPostChangeStatusUrl = `${baseUrl}/post/changeStatus`;
export const BlogPostDestroyUrl = `${baseUrl}/post/destroy`;

//FEATURE
export const WebFeatureUrl = `${baseUrl}/webfeature`;
export const WebFeatureStoreUrl = `${baseUrl}/webfeature/store`;
export const WebFeatureEditUrl = `${baseUrl}/webfeature/edit`;
export const WebFeatureUpdateUrl = `${baseUrl}/webfeature/update`;
export const WebFeatureChangeStatusUrl = `${baseUrl}/webfeature/changeStatus`;

//Testimonial
export const WebTestimonialStoreUrl = `${baseUrl}/testimonial/store`;
export const WebTestimonialEditUrl = `${baseUrl}/testimonial/edit`;
export const WebTestimonialUpdateUrl = `${baseUrl}/testimonial/update`;
export const WebTestimonialDeleteUrl = `${baseUrl}/testimonial/delete`;
export const WebTestimonialUrl = `${baseUrl}/testimonial`;
export const WebTestimonialChangeStatusUrl = `${baseUrl}/testimonial/changeStatus`;




//Testimonial
export const LandingTestimonialStoreUrl = `${baseUrl}/landingtestimonial/store`;
export const LandingTestimonialEditUrl = `${baseUrl}/landingtestimonial/edit`;
export const LandingTestimonialUpdateUrl = `${baseUrl}/landingtestimonial/update`;
export const LandingTestimonialDeleteUrl = `${baseUrl}/landingtestimonial/delete`;
export const LandingTestimonialUrl = `${baseUrl}/landingtestimonial`;
export const LandingTestimonialChangeStatusUrl = `${baseUrl}/landingtestimonial/changeStatus`;

//form

//Testimonial
export const FormStoreUrl = `${baseUrl}/form/store`;
export const FormEditUrl = `${baseUrl}/form/edit`;
export const FormUpdateUrl = `${baseUrl}/form/update`;
export const FormDeleteUrl = `${baseUrl}/form/delete`;
export const FormUrl = `${baseUrl}/form`;
export const FormChangeStatusUrl = `${baseUrl}/form/changeStatus`;

export const FunctionUrl = `${baseUrl}/webfunction`;
export const FunctionChangeStatusUrl = `${baseUrl}/webfunction/changeStatus`;

export const currencyUrl = `${baseUrl}/currency`;
export const currencyUrlAll = `${baseUrl}/currency/all`;
export const currencyStoreUrl = `${baseUrl}/currency/store`;
export const currencyEditUrl = `${baseUrl}/currency/edit`;
export const currencyUpdateUrl = `${baseUrl}/currency/update`;
export const currencyDetailsUrl = `${baseUrl}/currency/details`;
export const currencyDeleteUrl = `${baseUrl}/currency/delete`;
export const currencyChangeStatusUrl = `${baseUrl}/currency/changeStatus`;
export const currencySortOrderUrl = `${baseUrl}/currency/sortOrder`;


export const notificationUrl = `${baseUrl}/notification/get_unread_notification`;
export const notificationReadStatusUrl = `${baseUrl}/notification/is_read_status`;

export const leaveTypeUrl = `${baseUrl}/leaveType`;
export const leaveTypeStoreUrl = `${baseUrl}/leaveType/store`;
export const leaveTypeEditUrl = `${baseUrl}/leaveType/edit`;
export const leaveTypeUpdateUrl = `${baseUrl}/leaveType/update`;
export const leaveUrl = `${baseUrl}/leave`;
export const leaveStoreUrl = `${baseUrl}/leave/store`;
export const leaveStatusUrl = `${baseUrl}/leave/status`;

export const attendanceUrl = `${baseUrl}/attendance`;
export const attendanceCheckInUrl = `${baseUrl}/attendance/check-in`;
export const productOptionsvalueUrl = `${baseUrl}/product_options_value/list`;
export const paymentTypeUrl = `${baseUrl}/product_type`;
export const paymentTypeUrlAll = `${baseUrl}/paymentType/all`;
export const paymentTypeStoreUrl = `${baseUrl}/product_type/store`;
export const paymentTypeEditUrl = `${baseUrl}/product_type/edit`;
export const paymentTypeUpdateUrl = `${baseUrl}/product_type/update`;
export const paymentTypeDeleteUrl = `${baseUrl}/paymentType/delete`;
export const paymentTypeChangeStatusUrl = `${baseUrl}/product_type/changeStatus`;
export const paymentTypeSortorder = `${baseUrl}/product_type/sortOrder`;

export const fieldUrl = `${baseUrl}/product_type`;
export const fieldUrlAll = `${baseUrl}/field/all`;
export const fieldStoreUrl = `${baseUrl}/field/store`;
export const fieldEditUrl = `${baseUrl}/field/edit`;
export const fieldUpdateUrl = `${baseUrl}/field/update`;
export const fieldDeleteUrl = `${baseUrl}/field/delete`;
export const fieldChangeStatusUrl = `${baseUrl}/field/changeStatus`;

export const MediaUrlStore = `${baseUrl}/media/store`;
export const MediaUrlDelate = `${baseUrl}/media/destroy`;

export const TemplateSettingstore = `${baseUrl}/templateSetting/updateSetting`;



export const fieldsgroupUrl = `${baseUrl}/fieldsgroup`;
export const fieldsgroupUrlAll = `${baseUrl}/fieldsgroup/all`;
export const fieldsgroupStoreUrl = `${baseUrl}/fieldsgroup/store`;
export const fieldsgroupEditUrl = `${baseUrl}/fieldsgroup/edit`;
export const fieldsgroupUpdateUrl = `${baseUrl}/fieldsgroup/update`;
export const fieldsgroupDeleteUrl = `${baseUrl}/fieldsgroup/delete`;
export const fieldsgroupChangeStatusUrl = `${baseUrl}/fieldsgroup/changeStatus`;

export const couponUrlAll = `${baseUrl}/coupun`;
export const couponStoreUrl = `${baseUrl}/coupun/store`;
export const couponChangeStatusUrl = `${baseUrl}/coupun/changeStatus`;

export const couponviewUrl = `${baseUrl}/coupun/view`;

export const coupondeleteUrl = `${baseUrl}/coupun/delete`;
export const couponeditUrl = `${baseUrl}/coupun/edit`;
export const couponupdateUrl = `${baseUrl}/coupun/update`;